// body {
//   div {
//     color: aqua;
//   }
// }
html,
body {
  margin: 0;
  height: 100%;

}

#root {
  height: 100%;
}