.status-counts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.status-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
}

.status-item:last-child {
    border-bottom: none;
}

.status-label {
    font-weight: bold;
}

.status-count {
    font-weight: bold;
}

.status-0 .status-label,
.status-0 .status-count {
    color: red;
}

.status-1 .status-label,
.status-1 .status-count {
    color: #c87900;
}

.status-2 .status-label,
.status-2 .status-count {
    color: blue;
}

.status-3 .status-label,
.status-3 .status-count {
    color: rgba(0, 168, 80, 0.82);
}

.status-4 .status-label,
.status-4 .status-count {
    color: red;
}

.status-5 .status-label,
.status-5 .status-count {
    color: rgb(29, 213, 0);
}
