body {
  background-color: #f0f0f0; // 设置浅灰色背景
  margin: 0; // 去除默认的外边距
  padding: 0; // 去除默认的内边距
  font-family: Arial, sans-serif; // 可选，设置全局字体
}

.pdf-viewer-container {
  width: 100%;
  max-width: 900px; // 设置你想要的最大宽度
  height: 100%;
  padding: 0;
  margin: 0 auto; // 居中对齐
}

.pdf-viewer {
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 80%;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.signbox-buttonbox {
  display: flex;
  justify-content: center;
  margin: 30px auto 10px;
}

.sign-button {
  margin: 2px 10px;
}

.border-example {
  text-align: center;
}

.signature-canvas {
  width: 100%;
  max-width: 600px; // 设置签名画布的最大宽度
  height: 250px;
  border: 1px solid #1677ff;
  border-radius: 7px;
  margin: 10px auto; // 居中对齐
}

.sign-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .pdf-viewer {
    height: 60vh;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .signature-canvas {
    width: 95%;
    height: 250px;
    margin: 0 auto !important;
    border: 1px solid #1677ff;
    border-radius: 7px;
    margin: 10px 5px;
  }

  .signbox-buttonbox {
    display: flex;
    justify-content: space-between;
    margin: 10px 20%;
  }
}
